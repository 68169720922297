import * as React from "react";
import { Navigation } from "../components/global/nav"
import { Footer } from '../components/global/footer'
import "../styles.css"
import SEO from "../components/global/seo";
import CookiePrompt from "../components/global/cookies-prompt";
import Breadcrumbs from "../components/global/breadcrumbs";

const ManageAccount = () => {
   return (
       <main>
           <SEO
               title="Manage your account"
               slug="account"
               metadescription="Through your my account area, you can manage your Smart Plan account, book repairs and manage your details. Log on today to see the benefits."
               image=""
               titleTemplate="%s - Smart Plan"
           />
           <CookiePrompt/>
           <Navigation />
               <div className="standard-title-container default">
                   <div className="standard-title-inner-container body-inner-width">
                       <div className="standard-title-inner-content">
                           <h1 id="title">Manage your Smart Plan account</h1>
                           <p>New or existing customer? Use this service to manage your account online without the faff of picking up the phone.</p>
                       </div>
                   </div>
               </div>

               <div className="standard-container">
                   <div className="standard-inner-container body-inner-width">
                       <Breadcrumbs 
                           location="account"
                       />
                       <p>This page is under construction</p>
                   </div>
               </div>
           <Footer />
       </main>
   )
}

export default ManageAccount